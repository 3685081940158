.animate__delay-6s {
  -webkit-animation-delay: calc(1s * 6);
  animation-delay: calc(1s * 6);
}
.animate__delay-7s {
  -webkit-animation-delay: calc(1s * 7);
  animation-delay: calc(1s * 7);
}
.animate__delay-8s {
  -webkit-animation-delay: calc(1s * 8);
  animation-delay: calc(1s * 8);
}
