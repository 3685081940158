@use "variables";
@import "~animate.css/animate";

.home-carousel {
  margin: -1rem 0 0;
  position: relative;

  .container {
    padding-top: 80px;
  }

  #item-1 {
    background-image: url("../../assets/slideshow01.jpg");
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;

    h1 {
      text-shadow: 0 1px 2px white;
      font-weight: bold;
    }
    h3 {
      color: $ws-red;
      text-shadow: 0 1px 2px white;
      font-weight: bold;
    }
    ul > li {
      text-shadow: 0 1px 2px white;
      font-weight: bold;
      font-size: 20px;
      padding: 5px 0;
      svg {
        color: $ws-blue;
      }
    }
  }
  #item-2 {
    background-image: url("../../assets/slideshow02.jpg");
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;

    ul > li {
      & > span {
        display: inline-block;
        margin-bottom: 3px;
        padding: 3px 15px;
        background: $ws-red;
        font-size: 20px;
        color: white;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.175);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.175);
      }
    }
  }
  #item-3 {
    background-image: url("../../assets/slideshow03.jpg");
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;

    h3 {
      color: $ws-red;
    }
    p {
      display: block;
    }
  }
}

.carousel-inner > .carousel-item {
  min-height: 600px;
  height: calc(100vh - 120px);
  max-height: 1000px;
}
