$fa-font-path: "../webfonts";
$blue-gray: #2e3e4f;
$gray: #717171;
$blue-green: #0ac3ab;
$purple: #a88add;
$light-gray: #f0f0f0;
$yellow: #fcc900;

$ws-blue: #1f8bc5;
$ws-red: #ed3c25;

$sidebar-width: 220px;

$primary: $blue-green;
$dark: $blue-gray;
