main > .container {
  padding: 0 15px 40px;
}

.wrapper {
  transition: all 0.5s ease;
}

.wrapper.toggled {
  padding-left: $sidebar-width;
}

.main-content {
  height: 100vh;
  position: absolute;
  transition: all 0.5s ease;
  background-color: #f0f0f0;

  main {
    background-color: #f0f0f0;
  }
}

h1 + p {
  font-size: 1.25rem;
  font-weight: 300;
}

.tm-n3 {
  margin-top: -1rem;
}
