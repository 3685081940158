table thead {
  background: $gray;
  color: #fff;
}

.table .sortable {
  position: relative;
  cursor: pointer;
}

.table .sortable:after {
  @extend %fa-icon;
  @extend .fas;
  content: fa-content($fa-var-sort);
  position: absolute;
  right: 5px;
  top: 30%;
  color: #a4a4a4;
}

.table .sortable.ascending:after {
  content: fa-content($fa-var-sort-up);
  color: #fff;
}

.table .sortable.descending:after {
  content: fa-content($fa-var-sort-down);
  color: #fff;
}

.table thead {
  .company {
    width: 25%;
  }
  .price {
    white-space: nowrap;
    width: 10%;
  }
  .next-due-date {
    white-space: nowrap;
    width: 14%;
  }
  .overdue {
    width: 5%;
  }
}
