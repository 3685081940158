.rbc-event-content {
  font-size: 0.7em;
}

.rbc-month-view {
  background-color: #ffffff;
}

.rbc-toolbar-label {
  font-size: 2rem;
  font-weight: bold;
}
