.sidebar {
  position: fixed;
  height: 100%;
  min-width: $sidebar-width;
  max-width: $sidebar-width;
  color: #adb5bd;
  margin-left: -$sidebar-width;
  transition: all 0.5s;
}

.sidebar.is-open {
  transition: 0.5s;
}
